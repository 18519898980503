<template>
  <div>
    <el-dialog
      :visible="exitChannelDialogVisible"
      width="468px"
      title="提示"
      destroy-on-close
      @close="handleClose"
    >
      <div class="body-wrapper">
        您已退出当前直播间，请刷新页面后重新进入
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="mini"
          @click="handleClose"
        >
          刷新
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    exitChannelDialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

    };
  },
  methods: {
    handleClose() {
      window.location.reload();
      this.$emit('update:visible', false);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding: 26px 30px;
}

.body-wrapper {
  font-size: 14px;
  color: #333b44;
  line-height: 24px;
  margin-bottom: 8px;
}
</style>
